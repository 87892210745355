import { Typography } from "../Typography";
import { Box } from "../Box";
import {cn} from "~/component/ui/lib/utils";

interface Props {
  form: Form;
  className?: string;
}

/**
 * Displays the Api error.
 */
export function FormError({ form, className }: Readonly<Props>) {
  return (
    <>
      {form.formState.errors.root?.apiError?.message && (
        <Box className={className}>
          {/* TODO: */}
          {console.debug({ formState: form.formState })}
          <FormErrorMessage
            message={form.formState.errors.root.apiError.localizedMessage ?? form.formState.errors.root.apiError.message}
          />
        </Box>
      )}
    </>
  )
}

export function FormErrorMessage({ message }: Readonly<{ message: string }>) {
  if (!message) {
    return null
  }
  return (
    <Typography className={cn("ml-2 color-rose-500 my-2 text-center")}>
      {/* have at least a space to keep the space */}
      {message ?? " "}
    </Typography>
  )
}