import { z } from "zod";
import { LoggedInProDto } from "./pro.dto";
import { AUTH_CODE_LENGTH } from "~/constant/api.constant";

export const authDtoSchema = z.object({
  email: z.string().email(),
});
export type ProAuthDto = z.infer<typeof authDtoSchema>;

export const authConfirmDtoSchema = z.object({
  email: z.string().email(),
  authCode: z.string().min(AUTH_CODE_LENGTH).max(AUTH_CODE_LENGTH),
});
export type ProAuthConfirmDto = z.infer<typeof authConfirmDtoSchema>;

export interface ProAuthTokenDto {
  token: string;
  loggedInPro: LoggedInProDto;
}
